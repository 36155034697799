.scl-a-code-display,
.scl-a-code-display pre {
    padding: 0;
    overflow: auto;
    background: none;
    color: var(--scl-color-brand--text-body) none;
    font-family: var(--scl-mono-font-family);
    font-weight: var(--scl-font-family-bold--font-weight);
    text-transform: uppercase;
}
